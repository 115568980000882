<template>
<div class="max-w-2xl mx-auto">
  <Header />
  <Counter />
</div>
</template>

<script>
import Counter from './components/Counter.vue'
import Header from './components/Header.vue';

export default {
  name: 'App',
  components: {
    Counter,
    Header
  }
}
</script>
