<template>
<div class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
  <div class="px-4 py-5 sm:px-6">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      {{ done }} / {{ goal }}
    </h3>
  </div>

  <div class="px-4 py-5 sm:px-6">
    <base-progress :percentage="progress" class="mx-2 mb-2 h-5">
      <span class="text-xs text-white w-full flex justify-end pr-2">{{progress}} %</span>
    </base-progress>
  </div>
</div>

<div class="mt-4 bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
  <div class="px-4 py-5 sm:px-6">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Last Edge
    </h3>
  </div>

  <div class="px-4 py-5 sm:px-6">
    {{ lastEdge }}
  </div>
</div>
</template>

<script>
import BaseProgress from "./BaseProgress";
const moment = require('moment');

async function getData() {
  const goal = process.env.VUE_APP_COUNT_GOAL;

  const data = await fetch('data');
  const json = await data.json();
  const done = json.done;
  const last = json.last;
  const progress = Math.floor(100/goal*done);

  return {
    goal,
    done,
    progress,
    last
  };
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  name: 'Counter',
  components: {
    BaseProgress,
  },
  data() {
    return {
      goal: 0,
      done: 0,
      progress: 0,
      last: 0,
      lastRefresh: 0
    }
  },
  created() {
    var self = this;
    setInterval(function () {
      self.lastRefresh = Date.now();
    }, 60000)
  },
  watch: {
    async lastRefresh() {
      const data = await getData();

      await sleep(1000);
      this.goal = data.goal;
      this.done = data.done;
      this.progress = data.progress;
      this.last = data.last;
    }
  },
  computed: {
    lastEdge() {
      console.log("Refresh - ID:", (this.last*this.lastRefresh));
      if (parseInt(this.last) > 0) {
        return moment(this.last).fromNow();
      }

      if (this.lastRefresh === 0) {
        return 'Loading ...';
      }

      return 'Never';
    }
  },
  async mounted() {
    const data = await getData();

    this.goal = data.goal;
    this.done = data.done;
    this.progress = data.progress;
    this.last = data.last;
  }
}
</script>
