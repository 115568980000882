<template>
<div class="max-w-2xl mx-auto">
  <CountUp :user="user" v-if="isAuthenticated" />
  <Discord v-else />
</div>
</template>

<script>
import CountUp from './components/CountUp.vue';
import Discord from "./components/Discord";
import ls from "localstorage-slim";

async function getUser(accessToken) {
  // return {
  //   token: accessToken,
  //   id: "771380104159952927",
  // };

  const discordResponse = await fetch(`${process.env.VUE_APP_BASE_URL}/discord/user/${btoa(accessToken)}`);

  return await discordResponse.json();
}

export default {
  name: 'Login',
  components: {
    CountUp,
    Discord
  },
  data() {
    return {
      user: {},
      isAuthenticated: false
    }
  },
  async mounted () {
    const queryToken = this.$route.query.token;
    if (queryToken !== undefined && queryToken !== "") {
      const token = atob(queryToken).split('|');
      ls.set("auth", {"token": this.$route.query.token,"a":token[0],"u":token[1], "s":Math.round(Math.random()*5)}, {"ttl":parseInt(token[3])});
      window.location = `${process.env.VUE_APP_BASE_URL}/login`;
    }

    const auth = ls.get("auth");
    if (auth !== null && auth.a !== undefined && auth.a !== "") {
      this.user = await getUser(auth.a);
      if (this.user.id !== undefined && this.user.id === process.env.VUE_APP_ADMIN_USER) {
        this.isAuthenticated = true;
      }
    }
  },
  computed: {
    queryToken() {
      return this.$route.query.token;
    }
  },
  watch: {
    async queryToken(newToken) {
      if (newToken !== undefined && newToken !== "") {
        const token = atob(newToken).split('|');
        ls.set("auth", {"token": this.$route.query.token,"a":token[0],"u":token[1], "s":Math.round(Math.random()*5)}, {"ttl":parseInt(token[3])});
        window.location = `${process.env.VUE_APP_BASE_URL}/login`;
      }

      const auth = ls.get("auth");
      if (auth !== null && auth.a !== undefined && auth.a !== "") {
        this.user = await getUser(auth.a);
        if (this.user.id !== undefined && this.user.id === process.env.VUE_APP_ADMIN_USER) {
          this.isAuthenticated = true;
        }
      }
    }
  }
}
</script>
