<template>
<div class="mb-4 bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
  <div class="flex px-4 py-5 sm:px-6 justify-center">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Lil' Lynn's Edge counter
    </h3>
  </div>
  <div class="flex px-4 py-5 sm:px-6 justify-center">
    <p class="text-gray-900">
      Read <a class="underline" target="_blank" href="https://www.reddit.com/r/Femaleorgasmdenial/comments/tn63wi/f24_double_or_nothing_edge_210x_or_0x_before/">here</a> why this website exists.
    </p>
  </div>
</div>
</template>

<script>
export default {
  name: 'Header'
}
</script>
