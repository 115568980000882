// Load TailwindCSS
import './styles/app.css';

import { createApp } from 'vue/dist/vue.esm-bundler'
import { createRouter, createWebHistory } from "vue-router";
import Home from './App.vue'
import Login from './Login.vue'

const routes = [
  { path: '/', component: Home },
  { path: '/login', component: Login },
];
const router = createRouter({
  history: createWebHistory(),
  routes
});

const app = createApp({});
app.use(router);
app.mount('#app');
