<template>
  <Counter />
<div class="mt-4 bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
  <div class="px-4 py-5 sm:px-6 text-center">
    <button type="button" @click="countUp()" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Add Edge</button>
    &nbsp;
    <a href="/" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">Back to Website</a>
  </div>
</div>
</template>

<script>
import Counter from './Counter.vue';

export default {
  name: 'CountUp',
  props: [
    'user'
  ],
  components: {
    Counter
  },
  methods: {
    async countUp() {
      if (this.user.id !== undefined && this.user.id === process.env.VUE_APP_ADMIN_USER) {
        await fetch(`${process.env.VUE_APP_BASE_URL}/count/${btoa(this.user.token)}`);
      }

      window.location.reload();
    }
  }
}
</script>
